import { styled } from "@mui/material";
import React, { FC, useCallback } from "react";
import theme from "../../theme";
import RaboIcon from "../RaboIcon/RaboIcon";
import PopoutLink from "../PopoutLink/PopoutLink";
import { NavLink } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import { connect, ConnectedProps } from "react-redux";
import { setActiveMenuItem } from "../../redux/actions";

const mapDispatchToProps = { setActiveMenuItem };
const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	item: MainMenuItemInterface;
	isActive: boolean;
	showIcon?: boolean;
	href: string | null;
	toggleCategory?: () => void;
	handleDrawerToggle?: (a: boolean) => void;
};

const IOPDrawerItem: FC<Props> = ({
	item,
	handleDrawerToggle,
	showIcon = true,
	href,
	toggleCategory,
	setActiveMenuItem,
}) => {
	const handleCategoryOnClick = () => {
		handleDrawerToggle && handleDrawerToggle(true);
		toggleCategory && toggleCategory();
	};

	const icon = showIcon && item.icon && (
		<StyledIcon onClick={handleCategoryOnClick}>
			<RaboIcon name={item.icon} color={theme.palette.primary.main} />
		</StyledIcon>
	);

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement>, item: MainMenuItemInterface) => {
			e.stopPropagation();
			setActiveMenuItem(item.id);
			handleDrawerToggle && handleDrawerToggle(false);
		},
		[handleDrawerToggle, setActiveMenuItem]
	);

	return (
		<>
			{"category" === item.type || !href ? (
				<StyledCategory
					onClick={toggleCategory}
				>
					<span>

						<StyledContent >
							{icon}
							{item.label}
						</StyledContent>
					</span>
					
				</StyledCategory>
			) : (
				<StyledLinkWrapper>
					{"external" === item.type && href ? (
						<StyledContent>
							<StyledLink
								to={href}
								target="_blank"
								rel="noopener noreferrer"
								onClick={(e) => handleClick(e, item)}
							>
								{icon}
								{item.label}
							</StyledLink>
						</StyledContent>

					) : (
						<StyledLink
						to={href}
						onClick={(e) => handleClick(e, item)}
					>
						<StyledContent>


						
							
								{icon}
								{item.label}
								</StyledContent>

						</StyledLink>
					)}
					<StyledLinkIcons  					

					>

						{item.documentationUrl && (
							<StyledLink
								to={`/instructions/${item.path}`}
								onClick={(e) => handleClick(e, item)}
							><StyledContent>
									<DescriptionIcon />
								</StyledContent>
							</StyledLink>
						)}

						{item.popoutUrl && <PopoutLink url={item.popoutUrl} />}

					</StyledLinkIcons>
					

				</StyledLinkWrapper>
			)}
		</>
	);
};


const StyledContent = styled('div')(({ theme }) => ({
	alignItems: "center",
	display: "flex",
}));

const StyledIcon = styled('div')(({ theme }) => ({
	paddingRight: theme.spacing(2.5),
}));

const StyledLink = styled(NavLink)(({ theme }) => ({
	display: "inline-flex",
	alignItems: "center",
	flexGrow: 1,
	textDecoration: "none",
	color: theme.palette.primary.main,
	paddingBottom: theme.spacing(1),
	paddingTop: theme.spacing(1),
	"&:hover": { color: "#fff" },
}));

const StyledLinkIcons = styled('div')(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	"& a": {
		color: theme.palette.primary.main,
		"&:hover": { color: "#fff" },
	},
}));

const StyledLinkWrapper = styled('div')(({ theme }) => ({
	alignItems: "center",
	display: "flex",
	justifyContent: "space-between"
}));

const StyledCategory = styled('div')(({ theme }) => ({
	cursor: 'pointer',
	alignItems: "center",
	display: "flex",
	justifyContent: "space-between",
	paddingBottom: theme.spacing(1),
	paddingTop: theme.spacing(1),
}));


export default connector(IOPDrawerItem);