import { createTheme } from '@mui/material/styles';

export default createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 993,
			lg: 1286,
			xl: 1579,
		},
	},
	typography: {
		fontFamily: [
			"Myriad Regular",
			'"Segoe UI"',
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
		].join(","),
	},
	palette: {
		primary: {
			main: "#000099",
		},
		secondary: {
			light: "#ffb382",
			main: "#fd6400",
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				body1: {
					fontFamily: [
						"Myriad Regular",
						'"Segoe UI"',
						'"Helvetica Neue"',
						"Arial",
						"sans-serif",
					].join(","),
					width: "100%",
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				gutters: {
					paddingLeft: "18px",
					paddingRight: "18px",
				},
			},
		},
	},
});

