import React, { FC } from "react";
import "./svgxuse";
import "./RaboIcon";
import { styled } from '@mui/system';

interface Props {
	color: string;
	name: string;
}

const StyledIconWrapper = styled('div')(({color}) => ({
	backgroundColor: "#fff",
	border: `1px solid ${color}`,
	height: "24px",
	borderRadius: "50%",
	padding: "6px",
	width: "24px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

const StyledIcon = styled('img')(({color}) => ({
	display: "inline-block",
	height: "18px",
	strokeWidth: "0",
	stroke: color,
	fill: "currentColor",
	width: "24px",
}));



const RaboIcon: FC<Props> = ({ name, color }) => {

	return (
		<StyledIconWrapper color={color}>
			<StyledIcon src={`/SVG/${name}.svg`} alt="React Logo" color={color} />
		</StyledIconWrapper>
	);
};

export default RaboIcon;
