import Button from "@mui/material/Button";
import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { setUserAction } from "../../redux/actions";

const mapDispatchToProps = { setUser: setUserAction };

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;
const connector = connect(null, mapDispatchToProps);

const LogoutButton: FC<Props> = ({ setUser }) => {
	const handleLogoutButtonClick = () => {
		/**const url = new URL(`${process.env.REACT_APP_API_URL}/user/logout`);*/
		const url = new URL(`http://localhost:5000/api/user/logout`);
		fetch(url.toString(), {
			credentials: "include",
		}).then((response) => response.json())
			.then((json) => {
				setUser(null);
				json.logoutUrl && window.location.replace(json.logoutUrl);
			});
	};

	return (
		<Button
			variant="contained"
			color="secondary"
			onClick={handleLogoutButtonClick}
		>
			Log out
		</Button>
	);
};

export default connector(LogoutButton);
