import { Grid, LinearProgress } from "@mui/material";
import React, { FC } from "react";

const LoadingScreen: FC = () => (
	<Grid
		container
		spacing={0}
		alignItems="center"
		justifyContent="center"
		style={{ minHeight: "100vh" }}
	>
		<Grid item xs={6}>
			<LinearProgress color="secondary" />
		</Grid>
	</Grid>
);

export default LoadingScreen;
