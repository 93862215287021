import { Paper } from "@mui/material";
import { styled } from '@mui/system';
import React, { FC } from "react";
import theme from "../../theme";

interface Props {
	url: string;
}

const EmbedTile: FC<Props> = ({ url }) => {
	const RootContainer = styled(Paper)(() => ({
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		width: "100%",
		iframe: {
			maxWidth: "100%",
			minHeight: "200px",
		},
	}));

	return (
		<RootContainer>
			<iframe src={url} title="Embed tile" />
		</RootContainer>
	);
};

export default EmbedTile;