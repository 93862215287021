import React, { FC, useEffect, useRef, useState } from "react";
import { CircularProgress, ListItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/system";

import IOPDrawerWrapper from "../IOPDrawerWrapper/IOPDrawerWrapper";
import UserLink from "../UserLink/UserLink";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { fetchMenuAction, setActiveTrailAction } from "../../redux/actions";
import theme from "../../theme";

interface Props {
	activeMenuItem: string;
	menu: MainMenuItemInterface[];
	fetchMenu: any;
	setActiveTrail: any;
}

const IOPDrawer: FC<Props> = ({
	activeMenuItem,
	menu,
	fetchMenu,
	setActiveTrail,
}) => {
	const [open, setOpen] = useState(false);
	const toolbarRef = useRef<HTMLDivElement>(null);
	const handleDrawerToggle = (state: boolean) => {
		setOpen(state);
	};

	/**
	 * Get menu items from the backend and save them in the store.
	 */
	useEffect(() => {
		fetchMenu();
	}, [fetchMenu]);

	/**
	 * Create an array of the active menu item and all of its parents.
	 */
	useEffect(() => {
		const activeTrail = (data: MainMenuItemInterface[], value: string) => {
			let tree: string[] = [];
			for (const item of data) {
				if (item.children?.length > 0) {
					tree = activeTrail(item.children, value);
					if (tree[tree?.length - 1] === value) {
						tree.push(item.id);
						return tree;
					}
				}
				if (item.id === value) {
					tree.push(item.id);
					return tree;
				}
			}
			return tree;
		};

		setActiveTrail(activeTrail(menu, activeMenuItem) || []);
	}, [activeMenuItem, menu, setActiveTrail]);

	return (
		<StyledDrawer
			variant={'permanent'}
			className={open ? 'MuiDrawer-open' : 'MuiDrawer-close'}

			classes={{
				paper: (open ? 'MuiPaper-open' : 'MuiPaper-close'),
			}}
		>
			{menu?.length ? (
				<StyledScrollBars

					style={{ height: "calc(100vh - 136px)", overflowX: "hidden" }}
					hideTracksWhenNotNeeded={true}
				>
					{menu?.map((item) => (
						<IOPDrawerWrapper
							key={item.id}
							drawerItem={item}
							depth={0}
							drawerOpen={open}
							handleDrawerToggle={handleDrawerToggle}
						/>
					))}
				</StyledScrollBars>
			) : (
				<ListItem>
					<CircularProgress color="secondary" />
				</ListItem>
			)}

			<Divider />

			<StyledToolbar
				className={open ? 'MuiToolbar-open' : 'MuiToolbar-close'}
				ref={toolbarRef}
			>
				<StyledUserLinkWrapper>
					<UserLink />
				</StyledUserLinkWrapper>

				<StyledToggleButton
					onClick={() => handleDrawerToggle(!open)}
				>
					{open ? (
						<ChevronLeftIcon fontSize="large" />
					) : (
						<ChevronRightIcon fontSize="large" />
					)}
				</StyledToggleButton>
			</StyledToolbar>
		</StyledDrawer>
	);
};

const drawerWidth = 400;

const StyledScrollBars = styled(Scrollbars)(({ theme }) => ({
	"& > div:nth-child(2)": {
		display: "none",
	},
	"& > div:nth-child(3)": {
		left: "0",
	},
}));

const StyledDrawer = styled(Drawer)(() => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	'&.MuiDrawer-close, .MuiPaper-close': {
		display: "flex",
		flexDirection: "column",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: parseInt(theme.spacing(9)) + 1,
		[theme.breakpoints.up("sm")]: {
			width: parseInt(theme.spacing(9)) + 1,
		},
	},
	'&.MuiDrawer-open, .MuiPaper-open': {
		justifyContent: "space-between",
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
}));

const StyledToolbar = styled('div')(() => ({
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
	alignItems: 'flex-end',
	justifyContent: 'flex-end',
	padding: theme.spacing(1, 2),
	minHeight: '122px',
	position: 'fixed',
	bottom: '0',
	width: drawerWidth,
	backgroundColor: '#fff',
	boxSizing: 'border-box',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflow: 'hidden',
	'&.MuiToolbar-open': {
		width: drawerWidth,
	},
	'&.MuiToolbar-close': {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: parseInt(theme.spacing(9)) + 1,
	},
}));

const StyledUserLinkWrapper = styled('div')({
	width: "100%",
});

const StyledToggleButton = styled('div')({
	margin: "4px",
	padding: "0",
});

const mapStateToProps = (state: { activeMenuItem: any; menu: any }) => {
	return {
		activeMenuItem: state.activeMenuItem,
		menu: state.menu,
	};
};

const mapDispatchToProps = {
	fetchMenu: fetchMenuAction,
	setActiveTrail: setActiveTrailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IOPDrawer);
