import { styled } from '@mui/system';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import theme from "../../theme";
import EmbedTile from "../EmbedTile/EmbedTile";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import TextTile from "../TextTile/TextTile";
import { fetchPage } from "../../api/api";

const ContentPage: FC = () => {
	const { path } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [content, setContent] = useState<Page>();
	const [background, setBackground] = useState<{
		image: string | undefined;
		color: string | undefined;
	}>({ image: "#f4f4f4", color: undefined });

	const RootContainer = styled("div")(() => ({
		backgroundColor: background.color,
		backgroundImage:
			background.image && `url('/images/${background.image}-1024.jpg')`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		boxSizing: "border-box",
		flexGrow: 1,
		minHeight: "100%",
		padding: theme.spacing(3),
		[theme.breakpoints.up("lg")]: {
			backgroundImage:
				background.image && `url('/images/${background.image}-1920.jpg')`,
		},
		[theme.breakpoints.up("xl")]: {
			backgroundImage:
				background.image && `url('/images/${background.image}-3840.jpg')`,
		},
	}));

	const setPage = useCallback(async () => {
		setIsLoading(true);
		const response = await fetchPage(path || "home");
		response && setContent(response);
		setIsLoading(false);
	}, [path]);

	/**
	 * Get page from the backend.
	 */
	useEffect(() => {
		setPage();
	}, [setPage]);

	useEffect(() => {
		if (content) {
			const background =
				null === content.background
					? { color: undefined, image: undefined }
					: /^#([0-9A-F]{3}){1,2}$/i.test(content.background)
					? { color: content.background, image: undefined }
					: { color: undefined, image: content.background };
			setBackground(background);
		}
	}, [content]);

	/**
	 * Make sure an item is of type TextTile.
	 *
	 * @param tile
	 */
	const isTextTile = (tile: Tile): tile is TextTile => {
		return (tile as TextTile).body !== undefined;
	};

	/**
	 * Make sure an item is of type EmbedTile.
	 *
	 * @param tile
	 */
	const isEmbedTile = (tile: Tile): tile is EmbedTile => {
		return (tile as EmbedTile).url !== undefined;
	};

	return isLoading ? (
		<LoadingScreen />
	) : content?.title || content?.items?.length ? (
		<RootContainer>
			<Grid container spacing={3}>
				{content?.title && (
					<Grid item xs={12}>
						<Paper sx={{ backgroundColor: "rgba(255, 255, 255, 0.75)", p: 2, color: theme => theme.palette.text.secondary }}>
							<h1>{content.title}</h1>
						</Paper>
					</Grid>
				)}

				{content?.items?.map((item, i) => (
					<Grid
						container
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
						xl={2}
						key={i}
						alignItems="stretch"
						justifyContent="space-between"
					>
						{isTextTile(item) && (
							<TextTile title={item.title} body={item.body} />
						)}
						{isEmbedTile(item) && <EmbedTile url={item.url} />}
					</Grid>
				))}
			</Grid>
		</RootContainer>
	) : (
		<NotFoundPage />
	);
};

export default ContentPage;