import React, { FC, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import "./IFrame.scss";
import { PowerBIEmbed } from "powerbi-client-react";
import {models, Report} from 'powerbi-client';
import { stringify } from "querystring";
import { fetchPowerbi } from "../../api/api";
import styled from "@emotion/styled";
const mapState = (state: State) => ({ menu: state.menu });


type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { type: "embed" | "instructions" };
const connector = connect(mapState);



const IFrame: FC<Props> = ({ menu, type }) => {
	const { path } = useParams();
	const [url, setUrl] = useState<string | null>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [report, setReport] = useState<Report | null>();

	const [token, setToken] = useState<string>('');

	useEffect(() => {
		const acToken = async () => {
			setToken(await fetchPowerbi());
			console.log(await fetchPowerbi())
		}
		acToken()
	},[]);

	
	const findById = useCallback((arr: any[], path: any):
		| MainMenuItemInterface
		| undefined => {
		if (arr?.length === 0) return;
		return (
			arr.find((d) => d.path === path) ||
			findById(
				arr.flatMap((d) => d.children || []),
				path
			) ||
			null
		);
	}, []);

	useEffect(() => {
		const data = findById(menu, path);
		setUrl(type === "embed" ? data?.url : data?.documentationUrl);
		setTimeout(() => setIsLoading(false), 1000);
	}, [findById, menu, path, type]);






	return isLoading ? (
		<LoadingScreen />
	) : url ? (
		<iframe src={url} title="Embed" />
	) : (
		<NotFoundPage />
	);
};


export default connector(IFrame);
