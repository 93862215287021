import {
	Grid,
	Paper,
	styled,
} from "@mui/material";
import React from "react";

const NotFoundPage = () => {
	return (
		<StyledRoot container spacing={3}>
			<Grid item xs={12}>
				<StyledPaper>
					<h1>Pagina niet gevonden</h1>
				</StyledPaper>
			</Grid>
		</StyledRoot>
	);
};

const StyledRoot = styled(Grid)(({theme}) => ({
	minHeight: "100%",
	padding: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({theme}) => ({
	padding: theme.spacing(3),
}));


export default NotFoundPage;
