import queryString from "query-string";

//const apiUrl = "http://localhost:5000/api";
const apiUrl = process.env.REACT_APP_API_URL;

const handleErrors = (response: any) => {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
};

export const fetchMenu = async () => {
	let url = new URL(`${apiUrl}/menu`);
	const { simulate_roles } = queryString.parse(window.location.search);
	/**
	 * When a parameter simulate_roles is available, pass it to menu api
	 * to simulate a user with specific roles.
	 */
	if ( simulate_roles ) {
		url.searchParams.append("simulate_roles", simulate_roles);
	} 

	const menuItems = await fetch(
		url.toString(), {
			mode: 'cors',
			credentials: "include",
		})
		.then(handleErrors)
		.then((response) => response.json())
		.catch((error) => console.log(error));

	// const menuItems = await fetch(`${apiUrl}/menu`, {
	// 	credentials: "include",
	// })
	// 	.then(handleErrors)
	// 	.then((response) => response.json())
	// 	.catch((error) => console.log(error));
	// console.log("Menu items:", menuItems)

	return menuItems;
};


export const fetchPage = async (path: string) => {
	let url = new URL(`${apiUrl}/page/${path}`);
	const { simulate_roles } = queryString.parse(window.location.search);
	/**
	 * When a parameter simulate_roles is available, pass it to page api
	 * to simulate a user with specific roles.
	 */
	if ( simulate_roles ) {
		url.searchParams.append("simulate_roles", simulate_roles);
	} 

	const page = await fetch(
		url.toString(), {
			mode: 'cors',
			credentials: "include",
		})
		.then(handleErrors)
		.then((response) => response.json())
		.catch((error) => console.log(error));

	// const page = await fetch(`${apiUrl}/page/${path}`, {
	// 	credentials: "include",
	// })
	// 	.then(handleErrors)
	// 	.then((response) => response.json())
	// 	.catch((error) => console.log(error));

	return page;
};

export const fetchPowerbi = async () => {
	let url = new URL(`${apiUrl}/powerbi2`);
	const { code, state } = queryString.parse(window.location.search);

	if (code) {
		url.searchParams.append("state", state);
		url.searchParams.append("code", code);
	} else {
		url.searchParams.append("redirect_uri", window.location.origin + "/&powerbi");
		url.searchParams.append("user_redirect", window.location.href);
	}

	const response = await fetch(
		url.toString(),
		code 
			? { mode: 'cors', method: "POST", credentials: "include" }
			: { mode: 'cors', credentials: "include" }
	)
		.then((response) => response.json())
		.then((json) => json);

	response.authUrl && window.location.replace(response.authUrl);
	return response.powerbi.access_token
};


/**
 * Get a user from the backend. If not logged in yet, a url to a login
 * screen will be returned which will redirect to this app provide a
 * code and state parameter. These parameters can be send to the
 * backend to login and return the requested user.
 */
export const fetchUser = async () => {
	const { code, state, powerbi } = queryString.parse(window.location.search);
	let url = new URL(`${apiUrl}/user`);

	if(powerbi){
		url = new URL(`${apiUrl}/powerbi`);
	}
	if (code && state) {
		url.searchParams.append("state", state);
		url.searchParams.append("code", code);
	} else {
		url.searchParams.append("redirect_uri", window.location.origin + "/");
		url.searchParams.append("user_redirect", window.location.href);
	}

	const response = await fetch(
		url.toString(),
		code && state
			? { mode: 'cors', method: "POST", credentials: "include" }
			: { mode: 'cors', credentials: "include" }
	)
		.then((response) => response.json())
		.then((json) => json);

	response.authUrl && window.location.replace(response.authUrl);
	return response.firstName && response;
};
