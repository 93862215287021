import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	Typography,
} from "@mui/material";
import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LogoutButton from "../LogoutButton/LogoutButton";
import { styled } from "@mui/system";

const mapState = (state: UserState) => ({
	user: state.user,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;
const connector = connect(mapState);

const UserPage: FC<Props> = ({ user }) => {
	return (
		<StyledRoot>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper>
						<Card>
							<CardHeader
								avatar={
									<Avatar aria-label="recipe">
										<StyledAvatarImage
											src={user?.image}
											alt={user?.preferedName}
										/>
									</Avatar>
								}
								title={user?.preferedName}
								subheader={user?.emailAddress}
							/>
							<CardContent>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Typography>Roles</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<List>
											{user?.roles?.map((role, i) => (
												<ListItem key={i}>{role}</ListItem>
											))}
										</List>
									</AccordionDetails>
								</Accordion>
								<Divider />
								<StyledLogoutButton>
									<LogoutButton />
								</StyledLogoutButton>
							</CardContent>
						</Card>
					</Paper>
				</Grid>
			</Grid>
		</StyledRoot>
	);
};

const StyledRoot = styled('div')(({theme}) => ({
	padding: theme.spacing(3),
}));

const StyledLogoutButton = styled('div')(({theme}) => ({
	marginTop: theme.spacing(4),
}));

const StyledAvatarImage = styled('img')(({theme}) => ({
	height: "40px",
	width: "40px",
}));


export default connector(UserPage);
