import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { connect, ConnectedProps } from "react-redux";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const mapState = (state: UserState) => ({
	user: state.user,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;
const connector = connect(mapState);



const StyledAvatar = styled(Avatar)(({theme}) => ({
	marginRight: theme.spacing(2.25),
}));

const StyledAvatarImage = styled('img')(({theme}) => ({
	height: "40px",
	width: "40px",	
}));

const StyledLink = styled(Link)(({theme}) => ({
	alignItems: "center",
	color: theme.palette.primary.main,
	display: "flex",
	textDecoration: "none",
	"&visited": {
		color: theme.palette.primary.main,
	},
}));

const StyledUserDetails = styled('div')(({theme}) => ({
	textAlign: "left",
}));
const UserLink: FC<Props> = ({ user }) => {

	return (
		<StyledLink to="/user">
			<StyledAvatar aria-label="user">
				<StyledAvatarImage
					src={user?.image}
					alt={user?.preferedName}
				/>
			</StyledAvatar>
			<StyledUserDetails>
				<div>
					<Typography variant="body1" color="textPrimary">
						{user?.preferedName}
					</Typography>
				</div>
				<div>
					<Typography variant="body1" color="textSecondary">
						{user?.emailAddress}
					</Typography>
				</div>
			</StyledUserDetails>
		</StyledLink>
	);
};

export default connector(UserLink);
