import React, { FC, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { ThemeProvider, Grid, LinearProgress, styled } from "@mui/material";

import IFrame from "./components/IFrame/IFrame";
import UserPage from "./components/UserPage/UserPage";
import IOPDrawer from "./components/IOPDrawer/IOPDrawer";
import PowerBI from "./components/PowerBI/PowerBI";

import theme from "./theme";
import ContentPage from "./components/ContentPage/ContentPage";
import { fetchUserAction} from "./redux/actions";

const mapState = (state: State) => state;

const mapDispatchToProps = { fetchUser: fetchUserAction };

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { fetchUser: () => void };
const connector = connect(mapState, mapDispatchToProps);

// Use styled components for styling
const RootContainer = styled("div")({
  display: "flex",
  minHeight: "100vh",
});

const ContentContainer = styled("main")({
  backgroundColor: "#f4f4f4",
  display: "flex",
  flexGrow: 1,
});


const App: FC<Props> = ({ user, fetchUser, }) => {
	const [hasReloaded, sethasReloaded] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => sethasReloaded(true), 2000);
	}, []);

	useEffect(() => {
		fetchUser();
	}, [fetchUser]);



	useEffect(() => {
		user &&
			window.location.href !== user.userRedirect &&
			window.location.replace(user.userRedirect);
	}, [user]);

	return (
		<Router>
			<ThemeProvider theme={theme}>
				{user && hasReloaded ? (
					<RootContainer>
						<IOPDrawer />

						<ContentContainer>
							<Routes>
								<Route path="/" element={<ContentPage />} />
								<Route path="/powerbi/:path" element={<PowerBI type="powerbi" />} />
								<Route path="/embed/:path" element={<IFrame type="embed" />} />
								<Route path="/instructions/:path" element={<IFrame type="embed" />} />
								<Route path="/page/:path" element={<ContentPage />} />
								<Route path="/user" element={<UserPage />} />
							</Routes>
						</ContentContainer>

					</RootContainer>
				) : (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						spacing={0}
						style={{ minHeight: "100vh" }}
					>
						<Grid item xs={6}>
							<LinearProgress color="secondary" />
						</Grid>
					</Grid>
				)}
			</ThemeProvider>
			{!hasReloaded && (
				<iframe title="loadlogin"
					src="https://app.powerbi.com/autoAuthLogin?ctid=6e93a626-8aca-4dc1-9191-ce291b4b75a1"
					style={{ display: "none" }}
				/>
			)}
		</Router>
	);
};

export default connector(App);
