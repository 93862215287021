import React, { FC } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { NavLink } from "react-router-dom";

interface Props {
	url: string;
}

const PopoutLink: FC<Props> = ({ url }) => {
	return (
		<NavLink to={url} target="_blank" rel="noopener noreferrer">
			<LaunchIcon />
		</NavLink>
	);
};

export default PopoutLink;
