import React, { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ThemeProvider, styled, } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import HoverMenu from "../HoverMenu/HoverMenu";
import IOPDrawerItem from "../IOPDrawerItem/IOPDrawerItem";
import useHrefBuilder from "../../hooks/UseHrefBuilder";
import { setActiveMenuItem } from "../../redux/actions";
import theme from "../../theme";

const mapState = (state: {
	activeMenuItem: any;
	activeMenuTrail: string[];
}) => ({
	activeMenuItem: state.activeMenuItem,
	activeMenuTrail: state.activeMenuTrail,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	drawerItem: MainMenuItemInterface;
	drawerOpen: boolean;
	handleDrawerToggle: (open: boolean) => void;
	depth: number;
};
const connector = connect(mapState, {});

const IOPDrawerWrapper: FC<Props> = ({
	drawerItem,
	depth,
	drawerOpen,
	handleDrawerToggle,
	activeMenuItem,
	activeMenuTrail,
}) => {
	const [open, setOpen] = useState(false);
	const hrefBuilder = useHrefBuilder();
	const href = hrefBuilder(drawerItem);
	const inActiveTrail = activeMenuTrail.includes(drawerItem.id);
	const isActive = activeMenuItem
		? activeMenuItem === drawerItem.id
		: window.location.pathname === hrefBuilder(drawerItem);
	const [showHoverMenu, setShowHoverMenu] = useState<boolean>(false);
	const listItemTextRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (isActive) {
			setActiveMenuItem(drawerItem.id);
		}
	}, [drawerItem.id, isActive]);

	useEffect(() => {
		!drawerOpen && setOpen(false);
		drawerOpen && setShowHoverMenu(false);
	}, [drawerOpen]);

	const toggleCategory = () => setOpen(!open);

	const nestedDrawerItems = drawerItem.children?.map(
		(item: MainMenuItemInterface) => (
			<IOPDrawerWrapper
				key={item.id}
				drawerItem={item}
				depth={depth + 1}
				drawerOpen={drawerOpen}
				handleDrawerToggle={handleDrawerToggle}
				activeMenuItem={activeMenuItem}
				activeMenuTrail={activeMenuTrail}
			/>
		)
	);

	useEffect(() => {
		if (!listItemTextRef.current) return;
		listItemTextRef.current.style.marginLeft = `${depth * parseInt(theme.spacing(2))}px`;
	}, [depth]);

	const renderItem = () => {
		return (
			<StyledListItem
				className={
					clsx({
						isActive: isActive,
						inActiveTrail: isActive? false : inActiveTrail ,
					  })
				}
				onMouseEnter={() => setShowHoverMenu(!drawerOpen)}
				onMouseLeave={() => setShowHoverMenu(false)}
				
			>
				<StyledListItemText
					className="list-item-text"
					ref={listItemTextRef}
				>
					<IOPDrawerItem
						item={drawerItem}
						isActive={isActive}
						handleDrawerToggle={handleDrawerToggle}
						href={href}
						toggleCategory={toggleCategory}
					/>
				</StyledListItemText>

				{"category" === drawerItem.type &&
					drawerOpen &&
					(open ? <ExpandLess style={{cursor:"pointer"}} onClick={toggleCategory}/> : <ExpandMore style={{cursor:"pointer"}} onClick={toggleCategory}/>)}

				{showHoverMenu && (
					<HoverMenu
						category={drawerItem.children?.length > 0 ? drawerItem.label : null}
						items={
							drawerItem.children?.length > 0
								? drawerItem.children
								: [drawerItem]
						}
						showHoverMenu={showHoverMenu}
						indent={73}
						parentRef={listItemTextRef}
						handleDrawerToggle={handleDrawerToggle}
						toggleCategory={toggleCategory}
					/>
				)}
			</StyledListItem>
		);
	};

	return (
		<ThemeProvider theme={theme}>
			{renderItem()}

			{"category" === drawerItem.type ? (
				<Collapse
					in={open}
					timeout="auto"
					children={<List disablePadding={true}>{nestedDrawerItems}</List>}
				/>
			) : (
				<List disablePadding={true}>{nestedDrawerItems}</List>
			)}
		</ThemeProvider>
	);
};

// Use styled components for ListItem and ListItemText
const StyledListItem = styled(ListItem)(({theme}) => ({
	color: theme.palette.primary.main,
	//paddingLeft: "18",
	//paddingRight: "0",
	paddingBottom: "0",
	paddingTop: "0",
	"&.isActive":{
		backgroundColor: theme.palette.secondary.main,
		"& .list-item-text a": { color: "#fff" },
	},
	"&.inActiveTrail":{
		backgroundColor: theme.palette.secondary.light,
		"& .list-item-text a": { color: "#fff" },
	},	
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		"& .list-item-text a": {
			color: "#fff",
		},
	},
  }));
  
  const StyledListItemText = styled(ListItemText)(({ theme }) => ({
	marginBottom: "0",
	marginLeft: "0",
	marginTop: "0",
  }));

export default connector(IOPDrawerWrapper);
