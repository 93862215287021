import React, { FC, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import "./PowerBI.scss";
import { PowerBIEmbed } from "powerbi-client-react";
import {models, Report} from 'powerbi-client';
import { fetchPowerbi } from "../../api/api";
import styled from "@emotion/styled";
const mapState = (state: State) => ({ menu: state.menu });


type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { type: "embed" | "instructions" | "powerbi" };
const connector = connect(mapState);

function extractReportIdFromUrl(url: string): string  {
    const regex = '/reportId=([a-f0-9-]+)/';
    const match = url.match(regex);
    return match ? match[1] : '';
}



const PowerBI: FC<Props> = ({ menu, type }) => {
	const { path } = useParams();
	const [url, setUrl] = useState<string | null>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [report, setReport] = useState<Report | null>();

	const [token, setToken] = useState<string>('');

	useEffect(() => {
		const acToken = async () => {
			setToken(await fetchPowerbi());
			console.log(await fetchPowerbi())
		}
		acToken()
	},[]);

	
	const findById = useCallback((arr: any[], path: any):
		| MainMenuItemInterface
		| undefined => {
		if (arr?.length === 0) return;
		return (
			arr.find((d) => d.path === path) ||
			findById(
				arr.flatMap((d) => d.children || []),
				path
			) ||
			null
		);
	}, []);

	useEffect(() => {
		const data = findById(menu, path);
		setUrl(type === "powerbi" ? data?.url : data?.documentationUrl);
		setTimeout(() => setIsLoading(false), 1000);
	}, [findById, menu, path, type]);






	return isLoading ? (
		<LoadingScreen />
	) : url ? (
						<StyledEmbed 
				embedConfig = {{
					
					type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
					id: extractReportIdFromUrl(url),
					embedUrl: url,
					accessToken: token,
					tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
					settings: {
						personalBookmarksEnabled:true,
						panes: {
							bookmarks:{
								visible: true,
							},	
							filters: {
								expanded: true,
								visible: true
							}
						},
					}
				}}

				eventHandlers = {
					new Map([
						['loaded', function (event) {
							console.log('Report loaded');
							report?.bookmarksManager.getBookmarks.apply('default');
						}],
						['rendered', function () {console.log('Report rendered');}],
						['error', function (event) {console.log(event);}],
						['visualClicked', () => console.log('visual clicked')],
						['pageChanged', (event) => console.log(event)],
					])
				}

				cssClassName = { "reportClass" }

				getEmbeddedComponent={(embeddedReport) => {
					setReport(embeddedReport as Report);
					console.log(report)
				}}
			>
				
			</StyledEmbed>

			
			
	) : (
		<NotFoundPage />
	);
};

const StyledEmbed = styled(PowerBIEmbed)({
	'overflow': 'hidden'
});

export default connector(PowerBI);
