import {  Paper } from "@mui/material";
import React, { FC } from "react";
import { styled } from "@mui/system";


interface Props {
	title: string;
	body: string;
}

const StyledIconWrapper = styled(Paper)(({theme}) => ({
	padding: theme.spacing(2),
	color: theme.palette.text.secondary,
	width: "100%",
}));

const TextTile: FC<Props> = ({ title, body }) => {
	return (
		<StyledIconWrapper>
			<h2 dangerouslySetInnerHTML={{__html:title}} />
			<div dangerouslySetInnerHTML={{__html:body}} />
		</StyledIconWrapper>
	);
};

export default TextTile;
